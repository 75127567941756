import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Layout from '../components/Layout'
import Menu from '../components/Menu'
import Footer from '../components/Footer'

const CenterText = styled.div`
  ${tw`flex items-center justify-center max-w-50 text-xl`}
  min-height: 79vh;
`

const NotFound = () => (
  <>
    <Layout />
    <Menu />
    <CenterText>
      <p>
        <strong>404</strong> – this page doesn't exist 🙈
      </p>
    </CenterText>
    <Footer />
  </>
)

export default NotFound
